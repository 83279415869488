import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row, Col, Card, CardBody, CardHeader, Collapse, Modal, ModalBody, } from "reactstrap";
import headerImage from '../images/banner.jpg'
import logo from '../images/apfg-logodark-small-min.png'
import bawaRumah from '../images/green-bg.jpg'
import duaorang from '../images/dua-orang.png'
import apfglogic from '../images/apfg-logic.png'
import apfglogic2 from '../images/apfg-logic2.png'
import bganeh from '../images/bganeh.jpg'
import bganeh2 from '../images/bganeh2.jpg'
import banks from '../images/banks.jpg'
import assosiation from '../images/assosiation.jpg'
import { ContactForm } from "../components/contact-form";
import bg3 from '../images/blue-2-bg.jpg'
import maps from '../images/maps.png'
import testiYutub from '../images/testi-yutub.png'
import bgkunci from '../images/bgkunci.png'
import testimonial from '../images/testimonial.jpg'
import contactbg from '../images/contactbg.jpg'
import firebase from "gatsby-plugin-firebase"

import logos from '../images/logos.jpg'
import bgbanner from '../images/bg-banner.png'
import Helmet from "react-helmet";
import { Link } from "gatsby";

const IndexPage = () => {
  const [uid, setUid] = useState("")
  const [liveModal, setLiveModal] = React.useState(false);

  useEffect(() => {
    firebase.auth().signInAnonymously().then((user: any) => {
      // setUid(user.user?.uid)
      console.log("User Login", user.user)
      setUid(user.user.uid as any)
    }).catch((exc: any) => {
      console.error('Gagal login, error: ', exc);
    });
  }, [])

  const why = [
    {
      header: "Tax deductions Up to $10,000 a year",
      content: <>
        <p>Depending on your income level and tax rates, you can receive up to $10,000 annually in government tax incentives for property investment. We can help you not only claim this but also receive these payments weekly.</p>
        <p>Use this incentive and your investment property rental income. Therefore, owning an investment property is closer than you think. </p>
        <p>Receive tax deductions paid weekly, so your investment earns you benefits now when you need them.</p>
      </>
    }, {
      header: "65% of our clients now have a 3-year strategy to financial freedom",
      content: <>
        <p>By using experienced seasoned professionals for advice, you achieve your goals faster.</p>
        <p>Our qualified financial planners have decades of property and finance experience with top firms across the country. So you can rest assured you are getting the best advice.</p>
      </>
    }, {
      header: "Equity and high capital growth",
      content: <>
        <p>Equity is the value of the property vs what you own on the mortgage. Choosing the right property in the right area can mean a big difference in capital growth and therefore your equity. The faster we build equity, the faster you grow towards your financial future.</p>
        <p>We can then help you use this equity to buy more investment properties and speed up the process of financial freedom.</p>
      </>
    }, {
      header: "Secure income:",
      content: <>
        <p>Reliable tenants and regular rent payments will help you pay the mortgage without stressing your current income.</p>
        <p>Residential property can be a keystone to your wealth-building program.</p>
        <p>We can even assist with property management of your tenants giving you a hassle free investment. We help with:</p>
        <ul>
          <li>Dealing with tenants on a day-to-day basis</li>
          <li>Communicating with you when needed</li>
          <li>Overseeing maintenance and repairs</li>
          <li>Keeping to a set budget</li>
          <li>Carrying out checks on the property</li>
          <li>Managing rent</li>
        </ul>
      </>
    }, {
      header: "Interest rates are the lowest EVER in history",
      content: <>
        <p>Money has never been this cheap to borrow and therefore never been a better time to start investing in property.</p>
        <p>Low interest rates means lower monthly repayments, meaning more people than ever can afford to start  investing in property.</p>
        <p>This, along with strong rental demand and the current tax incentives, means you too can now start on the property investment ladder and get on the road to financial freedom.</p>
      </>
    },
  ]
  const [collapses, setCollapses] = React.useState([1]);
  const changeCollapse = (collapse: any) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter(prop => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  const whyList = []
  let lop = 1
  for (const w of why) {
    const x = lop
    whyList.push(
      <Card key={w.header} className="card-plain" style={{ marginBottom: 0 }}>
        <CardHeader id="headingOne" role="tab" style={{ background: '#2bbb9e', fontSize: 16, color: '#fff', fontWeight: 800, padding: 15 }}>
          <a
            aria-expanded={collapses.includes(x)}
            data-toggle="collapse"
            href="#pablo"
            onClick={e => {
              e.preventDefault();
              changeCollapse(x);
            }}
            style={{ color: '#fff' }}
          >
            {w.header}
            <i className="now-ui-icons arrows-1_minimal-down"></i>
          </a>
        </CardHeader>
        <Collapse isOpen={collapses.includes(x)}>
          <CardBody>
            {w.content}
          </CardBody>
        </Collapse>
      </Card>
    )
    lop++
  }

  return (

    <Layout seo={{ title: "Australian Property & Finance Group", description: 'Australian Property & Finance Group is your partner in finding the best options for first homes & properties, lending solutions and SMSF investments', keyword: '' }} >
      <Helmet>
        <style>
          {
            `
          
          .header-font1 {
            text-transform: uppercase;
            background: rgb(42, 187, 157);
            font-weight: 800;
            display: inline-block;
            padding: 10px 20px;
            color: #1e3340;
            margin-bottom: 10px;
        }
        li span {
          color: #2bbb9d;
          font-weight: 800;
      } 
      .testi  
        {
          color: #2bbb9e;
          font-size: 32px;
          font-weight: 800;
          text-transform: uppercase;
          line-height: 1.1;
      }
      .yutub {
        width: 100%;
        min-height: 500px;
      }
      .testibg {
        background-image: url(${bganeh});
      }
      .kunci {
        min-height: 600px;
        background-size: auto;
        background-position: left top;
        background-repeat: no-repeat;
      }
      .modal-content {
        border-radius: .1875rem;
        border: none;
        box-shadow: none;
        background: transparent;
    }
      .covid {
        background: #1d2f3dd1;
    color: #fff;
    padding: 40px;
    border-bottom: 30px solid #2bbb9d;
}  
.modal-content .modal-body {
  padding: 0;
  line-height: 1.9;
}
.modal .modal-header .close {
  color: #ff3636;
  text-shadow: none;
  z-index: 999;
  font-size: 30px;
  right: 0;
  top: 0;
}
          @media screen and (max-width: 768px) {
            .buku-left {
          background - image: url(${headerImage});
            }
            .yutub {
              width: 100%;
              min-height: 300px;
            }
            .testibg {
              background-image: url(${bganeh2});
            }
          } 
          `
          }
        </style>
      </Helmet>

      <section >
        <div className="banner" style={{ backgroundImage: `url(${headerImage})`, backgroundPosition: 'top center', backgroundSize: 'cover' }}>
          <div style={{ backgroundImage: `url(${bgbanner})`, backgroundPosition: 'top center', backgroundSize: 'cover' }}>
            <Container>
              <Row>
                <Col style={{ textAlign: 'center' }}>
                  <img src={logos} alt="Australian Property" />
                </Col>
              </Row>
              <Row>
                <Col md={6} style={{ color: '#fff', marginBottom: 50 }}>
                  <h3 className="header-font1">Property Investors!</h3>
                  <h3 style={{ marginBottom: 10, textTransform: "uppercase", fontWeight: 600 }}>Why you should book
                  your free wealth strategy
call now:</h3>
                  <ul style={{ fontSize: 16, fontWeight: 500 }}>
                    <li>Pay off your home loan in <span>7 years or less</span> </li>
                    <li>Reduce taxes by up to <span>$100,000 in 10 years</span></li>
                    <li><span>65% </span> of our clients now have a 3-year strategy to <span>financial freedom </span> </li>
                    <li><span>Interest rates</span> are the lowest EVER in history</li>
                  </ul>
                  <h4 style={{ marginTop: 15, fontWeight: 800, textTransform: "uppercase", color: '#ffd200' }}>SCHEDULE Your Free 20-Minute Wealth Strategy Call</h4>
                  <a href="#book" className="btn btn-primary" style={{ background: '#ffd200', color: '#000', fontSize: 20, fontWeight: 800 }}>BOOK YOUR CALL</a>
                </Col>

              </Row>
            </Container>
          </div>
        </div>
      </section>
      <section style={{ backgroundImage: `url(${bawaRumah})`, backgroundPosition: 'center', backgroundSize: "cover" }}>
        <Container>
          <Row className="justify-content-end">
            <Col md={6} className="order-md-last" style={{ marginTop: 50, marginBottom: 50 }}>
              <img src={apfglogic} alt="" />
              <h3 style={{ textTransform: 'uppercase', fontWeight: 800 }}>What we do as <br />a holistic solution: </h3>
              <ul style={{ fontSize: 16 }}>
                <li>We help you start or grow your investment portfolio</li>
                <li>Our clients are looking for the best advice, even if they are just starting or already have property</li>
                <li>Our team has decades of industry experience, working with some of Australia’s most reputable companies for finance, investment and real estate</li>
                <li>We research the best growth areas across the entire country for capital return and rental yield </li>
                
              </ul>
              <h4 style={{ marginTop: 15, fontWeight: 800, textTransform: "uppercase" }}>SCHEDULE Your Free 20-Minute Wealth Strategy Call</h4>
              <a href="#book" className="btn btn-primary" style={{ background: '#ffd200', color: '#000', fontSize: 20, fontWeight: 800 }}>BOOK YOUR CALL</a>
            </Col>
            <Col md={6} style={{ textAlign: 'center', marginTop: 50, backgroundImage: `url(${duaorang})`, backgroundSize: 'contain', backgroundPosition: 'bottom center', minHeight: 400, backgroundRepeat: 'no-repeat' }}>

            </Col>
          </Row>
        </Container>
      </section>
      <section style={{ backgroundImage: `url(${bg3})`, backgroundPosition: 'center', backgroundSize: 'cover', padding: '50px 0' }} >
        <Container>
          <Row>
            <Col className="" style={{ color: '#fff' }} >
              <h3 style={{ fontWeight: 800, textTransform: 'uppercase', color: '#2abb9d' }}>How we do it</h3>
              <p>Our team is by your side every step of the way, with advice, education and personalised client support.</p>
              <ul style={{ fontSize: 16 }}>
                <li>Nationwide Property selection</li>
                <li>National team of qualified financial advisors</li>
                <li>National broker network that can find you the best interest rates available</li>          
                <li>National tenant support to help manage your investment</li>
                <li>National team of accountants, conveyancers and legal support  </li>
              </ul>
              <h4 style={{ marginTop: 15, fontWeight: 800, textTransform: "uppercase", color: '#ffd200' }}>SCHEDULE Your Free 20-Minute Wealth Strategy Call</h4>
              <a href="#book" className="btn btn-primary" style={{ background: '#ffd200', color: '#000', fontSize: 20, fontWeight: 800 }}>BOOK YOUR CALL</a>
            </Col>
            <Col md={6} >
              <img src={maps} alt="" />
            </Col >
          </Row>
        </Container>
      </section>
      <section>
        <Row>
          <Col md={6}>
            <img src={bgkunci} alt="" />
          </Col>
          <Col md={6} style={{ marginTop: 75, marginBottom: 50 }}>
            <h3 style={{ fontWeight: 800, textTransform: 'uppercase', color: 'rgb(29 47 61)' }}>Why get on the property<br />investment ladder?</h3>
            <div
              aria-multiselectable={true}
              className="card-collapse"
              id="accordion"
              role="tablist"
              style={{ maxWidth: 630 }}
            >
              {whyList}
            </div>
          </Col>
        </Row>
      </section>
      <section style={{ background: '#ededed' }}>
        <Container>
          <Row className="justify-content-center" style={{ padding: '50px 0 50px', textAlign: 'center' }}>
            <Col md={12}>
              <h3 style={{ fontWeight: 800, textTransform: 'uppercase', color: '#2abb9d' }}>Associations And Accreditations</h3>
              <img src={assosiation} alt="" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="testibg" style={{  backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <Container>
          <Row>
            <Col md={6} style={{ marginTop: '10%' }}>

              <p className="testi"><span style={{ color: '#fff' }}>See for yourself</span><br />
what our clients have<br />
experienced with us </p>
              <h4 style={{ marginTop: 15, fontWeight: 800, textTransform: "uppercase", color: '#ffd200' }}>SCHEDULE Your Free 20-Minute Wealth Strategy Call</h4>
              <a href="#book" className="btn btn-primary" style={{ background: '#ffd200', color: '#000', fontSize: 20, fontWeight: 800 }}>BOOK YOUR CALL</a>
              <img src={apfglogic2} alt="" />
            </Col>
            <Col md={6} style={{ padding: '60px 15px' }}>
              <img src={testiYutub} alt="" onClick={() => setLiveModal(true)} style={{ cursor: "pointer" }} />
            </Col>
          </Row>
        </Container>
      </section>
      
      <section style={{ marginBottom: 50, marginTop: 75 }}>
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <h3 style={{ fontWeight: 800, textTransform: 'uppercase', color: 'rgb(29 47 61)', textAlign: "center" }}>It's All About The Perfect Strategy, Personalized For You</h3>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10}>
              <p style={{ textAlign: "center" }}>With APFG by your side, you have access to a wide range of options for lending, the latest numbers for interest rates and financing, a nationwide database of properties, and a team of experts alongside you to help you every step of the way.</p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h4 style={{ fontWeight: 800, textTransform: 'uppercase', color: '#2abb9d' }}>Location</h4>
              <p>We continually research the markets for the best states and suburbs for the highest potential of long-term growth.</p>
            </Col><Col md={6}>
              <h4 style={{ fontWeight: 800, textTransform: 'uppercase', color: '#2abb9d' }}>Price Range/Budget</h4>
              <p>You’ll work with our team of brokers to know your budget, how much you can borrow, and move quickly through the pre-approval with a full range of interest rates and financial products.</p>
            </Col><Col md={6}>
              <h4 style={{ fontWeight: 800, textTransform: 'uppercase', color: '#2abb9d' }}>Amenities</h4>
              <p>Happy tenants are long-term tenants. We want them to enjoy their home and stay in your property. So only work with properties that have met approved standards and that are in desirable locations and close and convenient to adequate amenities.</p>
            </Col><Col md={6}>
              <h4 style={{ fontWeight: 800, textTransform: 'uppercase', color: '#2abb9d' }}>New or Established</h4>
              <p>New homes can be beautiful and modern, while older houses have classic aesthetic and charm. What is the right investment for you? We’ll work with you to know which will give you the highest return, least amount of stress, and the most benefits for years to come.</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="contact" style={{ backgroundImage: `url(${testimonial})`, backgroundSize: 'cover', backgroundPosition: 'center', padding: '50px 0' }}>
        <Container>
          <Row>
            <Col md={6}>
              <div className="covid">
                <h3 style={{ fontWeight: 800, textTransform: 'uppercase', color: '#2abb9d' }}>Why Invest Now?</h3>
                <p>The COVID-19 recession won’t last forever. The earliest buyers will benefit most from the significant bounce-back that’s just around the corner.</p>
                <p>With government incentives to home buyers, existing properties will quickly disappear and rapid increase in pricing will soon occur. Don’t miss out on this fantastic opportunity to obtain capital growth quickly whilst property is still within your reach.</p>
                <p>Most properties are currently yielding 6% as an average, meaning properties are generating far more revenue than their holding cost.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      
      <section id="book" style={{ backgroundImage: `url(${contactbg})`, backgroundSize: 'cover', backgroundPosition: 'center', padding: '75px 0px 50px' }}>
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <h3 style={{ color: '#fff', textTransform: 'uppercase', textAlign: 'center' }}><span style={{ fontWeight: 800, color: "#2bbb9d" }}>Book Your Wealth</span> Strategy Call Today</h3>
              <ContactForm id={uid ? uid : ''} />
            </Col>
          </Row>
        </Container>
      </section>
      <section style={{ background: '#fff' }}>
        <Container>
          <Row className="justify-content-center" style={{ margin: '75px 0 50px', textAlign: 'center' }}>
            <Col md={12}>
              <h3 style={{ fontWeight: 800, textTransform: 'uppercase', color: '#2abb9d' }}>Featured Partners</h3>
              <p>We work with many of the world’s most reputable and trusted names for banking, mortgage financing and lending. With access to over 40 lenders, including all the majors, we search and find some of the lowest rates on the market.</p>
              <img src={banks} alt="" />
            </Col>
          </Row>
        </Container>
      </section>
      <footer className="footer footer-big" style={{ padding: 0, backgroundColor: '#333', color: '#fff' }}>

        <section id="book" style={{ background: `rgb(29 47 61)`, padding: '75px 0px 50px', textAlign: 'left' }}>
          <Container>
            <Row className="justify-content-center">
              <Col md={3}>
                <img src={logo} alt="" style={{ minWidth: 150 }} /> <br />
                <a className="social-link" href="https://www.facebook.com/Australian-Property-Finance-Group-112970687188763" target="_blank"><i className="fab fa-facebook-square"></i></a>
                <a className="social-link" href="https://www.instagram.com/australianpfg/" target="_blank"><i className="fab fa-instagram"></i></a>
                <a className="social-link" href="https://www.youtube.com/channel/UCkgQmGuq5h494o4veHFI7MQ" target="_blank"><i className="fab fa-youtube"></i></a>
                <a className="social-link" href="https://www.linkedin.com/in/australian-property-and-finance-group-2713781b5/" target="_blank"><i className="fab fa-linkedin"></i></a>
              </Col>
              <Col md={3}>
                <h5>Address</h5>
                <p style={{ color: '#fff' }}>
                  <span style={{ fontWeight: 800 }}>Australia Office</span><br />
Level 26, 1 Bligh Street,<br />
Sydney CBD 2000, NSW<br />
P. <a style={{ color: '#fff' }} href="tel:+61261003322">(02) 6100 3322</a><br />
E. <a style={{ color: '#fff' }} href="malto:info@apfg.com.au">info@apfg.com.au</a>
                </p>
              </Col>
              <Col md={3}>
                <h5>Help Center</h5>
                <ul className="links-vertical">
                  <li><Link to="https://apfg.com.au/credit-guide/">Credit Guide</Link></li>
                  <li><Link to="https://apfg.com.au/privacy-policy/">Privacy Policy</Link></li>
                  <li><Link to="https://apfg.com.au/contact/">Contact Us</Link></li>
                </ul>
              </Col>
              <Col md={3}>
                <iframe
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FAustralian-Property-Finance-Group-112970687188763&tabs=timeline&width=320&height=250&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1951132625023432" width="320" height="250"
                  scrolling="no"
                  allowTransparency={true} allow="encrypted-media"></iframe>
              </Col>
            </Row>
          </Container>
        </section>


        <section style={{ textAlign: 'center', padding: '15px 0' }}>
          © {(new Date).getFullYear} Australian Property and Finance Group. All Rights Reserved.
      </section>
      </footer>
      <Modal style={{ background: 'transparant' }} size="lg" isOpen={liveModal} toggle={() => setLiveModal(false)}>
        <div className="modal-header">

          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => setLiveModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <iframe className="yutub" src="https://www.youtube.com/embed/MvJcboB-xA0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} ></iframe>
        </ModalBody>

      </Modal>

    </Layout>
  )

}
export default IndexPage
