import React, { useState } from 'react'
import { Form, Row, Col, Input, Button } from 'reactstrap'
import DateTime from 'react-datetime';
import '../../node_modules/react-datetime/css/react-datetime.css'
import Helmet from 'react-helmet';
import moment from 'moment';
import firebase from "gatsby-plugin-firebase"

import Select from 'react-select';
type typePRops = {
    id: string
}
export function ContactForm(props: typePRops) {
    const [submited, setSubmited] = useState(false)
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [postCode, setPostCode] = useState()
    const [date, setDate] = useState()
    const [time, setTime] = useState()
    const [age, setAge] = useState()
    const [income, setIncome] = useState()
    const [job, setJob] = useState()
    const [emp, setEmp] = useState()
    const [superBamance, setSuperBamance] = useState()
    //const [home, setHome] = useState("")
    let campaign = ""
    let ads = ""
    let adset = ""
    if (typeof window !== 'undefined') {
        const urlParams = new URLSearchParams(window.location.search);
        campaign = urlParams.get('c')!
        ads = urlParams.get('a')!
        adset = urlParams.get('as')!
    }

    console.log("urlParams", campaign, ads, adset)
    const submitHendler = (event: any) => {
        event.preventDefault()
        const dt = moment(date).format("MMMM Do YYYY, h:mm:ss a")

        const dataPost = {
            id: props.id, name, email, date: new Date().toString(), phone,
            postCode, appoinmentDate: dt, age, income, home: "", superBamance, landingpage: "investment-landingpage",
            campaign: campaign ? campaign : 'General', ads: ads ? ads : 'General', adset: adset ? adset : 'General',
            jobTitle: job, employeeDetail: emp, time
        }
        firebase.database().ref(`websiteLeads/${props.id}`).set(dataPost).then((d: any) => {
            console.log("Sudah Lewat Sini", d)
            setSubmited(true)
        }).catch(
            (err: any) => {
                console.log(err)
            }
        )
        console.log(dataPost)
    }
    let formDisplay = (
        <div style={{ paddingTop: 30, borderRadius: 10, marginBottom: 20 }}>

            <Form style={{ margin: "0px 30px 0 30px" }} onSubmit={submitHendler}>
                <Row>
                    <Col style={{ margin: "5px 0" }}>
                        <Input
                            placeholder="Enter name"
                            type="text"
                            className="form-control"
                            onChange={(evt: any) => {
                                //console.log(evt.target.value)
                                setName(evt.target.value)
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ margin: "5px 0" }}>
                        <Input
                            placeholder="Enter email"
                            type="email"
                            className="form-control"
                            onChange={(evt: any) => {
                                setEmail(evt.target.value)
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ margin: "5px 0" }} md={8}>
                        <Input
                            placeholder="Phone Number"
                            type="text"
                            className="form-control"
                            onChange={(evt: any) => {

                                setPhone(evt.target.value)
                            }}
                        />
                    </Col>
                    <Col style={{ margin: "5px 0" }} md={4}>
                        <Input
                            placeholder="Post Code"
                            type="text"
                            className="form-control"
                            onChange={(evt: any) => {
                                setPostCode(evt.target.value)
                            }}
                        />
                    </Col>
                </Row>
                <p style={{ color: '#fff', marginTop: 15, marginBottom: 0 }}>Me and my partner</p>
                <Row>
                    <Col md={6} style={{ margin: "5px 0" }}>
                        <Select
                            className="react-select react-select-primary"
                            classNamePrefix="react-select"
                            onChange={(value: any) => {
                                setAge(value.value)
                            }}
                            options={[

                                { value: "Under 30yrs", label: "Under 30yrs" },
                                { value: "31-45yrs", label: "31-45yrs" },
                                { value: "46-60yrs", label: '46-60yrs' },
                                { value: "61yrs+", label: '61yrs+' },
                            ]}
                            placeholder="Age Range"
                        />
                    </Col>
                    <Col md={6} style={{ margin: "5px 0" }}>

                    </Col>
                </Row>
                <Row>
                    <Col md={6} style={{ margin: "5px 0" }}>
                        <Select
                            className="react-select react-select-primary"
                            classNamePrefix="react-select"
                            onChange={(value: any) => {
                                setIncome(value.value)
                            }}
                            options={[

                                { value: "Under $50k", label: "Under $50k" },
                                { value: "$50k-$80k", label: "$50k-$80k" },
                                { value: "$80k-$100k", label: '$80k-$100k' },
                                { value: "$100k+", label: '$100k+' },
                            ]}
                            placeholder="Annual Income"
                        />
                    </Col>
                    <Col md={6} style={{ margin: "5px 0" }}>
                        <Select
                            className="react-select react-select-primary"
                            classNamePrefix="react-select"
                            onChange={(value: any) => {
                                setSuperBamance(value.value)
                            }}
                            options={[

                                { value: "$0 - $179k", label: "$0 - $179k" },
                                { value: "$180k - $199k", label: "$180k - $199k" },
                                { value: "$200k+", label: '$200k+' },
                            ]}
                            placeholder="Super Balance"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} style={{ margin: "5px 0" }}>
                    <Input
                            placeholder="Job Description"
                            type="text"
                            className="form-control"
                            onChange={(evt: any) => {
                                setJob(evt.target.value)
                            }}
                        />
                    </Col>
                    <Col md={6} style={{ margin: "5px 0" }}>
                        <Select
                            className="react-select react-select-primary"
                            classNamePrefix="react-select"
                            onChange={(value: any) => {
                                setEmp(value.value)
                            }}
                            options={[

                                { value: "Full Time", label: "Full Time" },
                                { value: "Part Time", label: "Part Time" },
                                { value: "Self Employed", label: 'Self Employed' },
                                {value: "Contractor", label: "Contractor"},
                                {value: "Unemployed", label: "Unemployed"},
                            ]}
                            placeholder="Current Employment"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} style={{ margin: "5px 0" }}>
                        <DateTime
                            onChange={(val: any) => {
                                setDate(val)
                            }}
                            closeOnSelect={true}
                            timeFormat={false}
                            inputProps={{ placeholder: "Preferred Contact Date" }} />
                    </Col>
                    <Col md={6} style={{ margin: "5px 0" }}>
                    <Input
                            placeholder="Preferred Contact Time"
                            type="text"
                            className="form-control"
                            onChange={(evt: any) => {
                                setTime(evt.target.value)
                            }}
                        />
                    </Col>
                </Row>
                <Button color="info" type="submit" style={{ marginBottom: 30, fontSize: 18, background: 'rgb(255, 210, 0)', width: '100%', color: '#000', fontWeight: 800 }}  > Submit  </Button>
            </Form>
        </div>

    )
    console.log("submited", submited)
    if (submited) {
        formDisplay = <div style={{ minHeight: 400, background: 'rgba(255, 255, 255, .6)', marginTop: 20, padding: 30, color: '#000', fontWeight: 500 }}>
            <p style={{ color: '#000', fontWeight: 500 }}>Hi {name}, </p>
            <p style={{ color: '#000', fontWeight: 500 }}>Many thanks for your enquiry. We look forward to booking your FREE wealth training call with one of our nationally qualified financial planners. </p>
            <p style={{ color: '#000', fontWeight: 500 }}>One of your consultants will be in contact very shortly to ask a few pre-qualification questions and find a convenient time for you to speak with your financial planner.</p>
            <p style={{ color: '#000', fontWeight: 500 }}>For more information please contact us on</p>
            <p style={{ fontWeight: 500 }}>
                Phone <a style={{ fontWeight: 500 }} href="tel:+61261003322"> (02) 6100 3322 </a> <br />
                <a style={{ fontWeight: 500 }} href="mailto:info@apfg.com.au" target="_blank">info@apfg.com.au</a><br />
                <a style={{ fontWeight: 500 }} href="https://apfg.com.au/" target="_blank">https://apfg.com.au/</a><br />
            </p>
            
        </div>
    }
    return (
        <>
            <Helmet>
                <style>
                    {
                        `
                    .react-select__control {
                        border-radius: 20px;
                        font-size: 1.2em;
                    }
                    .form-control {
                        background-color: #fff;
                        font-size: 1.2em;
                      }
                      
                    
                    `
                    }
                </style>
            </Helmet>
            {formDisplay}
        </>
    )
}

/*
<Select
                            className="react-select react-select-primary"
                            classNamePrefix="react-select"
                            onChange={(value: any) => {
                                setHome(value.value)
                            }}
                            options={[

                                { value: "Own", label: "Own" },
                                { value: "Mortgage", label: "Mortgage" },
                                { value: "Rent", label: 'Rent' },
                            ]}
                            placeholder="Your Home?"
                        />
*/